import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: string[] = [];

export const searchHistorySlice = createSlice({
  name: 'searchHistory',
  initialState,
  reducers: {
    push: (state, action: PayloadAction<string>) => {
    
      // eslint-disable-next-line eqeqeq
      if (action.payload == 'pikachu'){
         console.error("ERROR: Can not save pikachu");
      }else{
        return [action.payload, ...state];
      }
      
    }
  }
});

export const { push } = searchHistorySlice.actions;

export default searchHistorySlice.reducer;
