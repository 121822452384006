import React from 'react';

export const PokeballIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="18px"
      height="18px"
      viewBox="0 0 288 288"
      enableBackground="new 0 0 288 288"
    >
      <g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#303030"
            d="M252.5,108.5v72h18v-72H252.5z M18.5,108.5v72h18v-72H18.5z
       M54.5,72.5h-18v36h18V72.5z M36.5,180.5v36h18v-36H36.5z M252.5,108.5v-36h-18v36H252.5z M234.5,216.5h18v-36h-18V216.5z
       M216.5,54.5v-18h-36v18H216.5z M108.5,36.5h-36v18h36V36.5z M180.5,252.5h36v-18h-36V252.5z M72.5,234.5v18h36v-18H72.5z
       M108.5,270.5h72v-18h-72V270.5z M180.5,18.5h-72v18h72V18.5z M72.5,54.5h-18v18h18V54.5z M234.5,72.5v-18h-18v18H234.5z
       M54.5,216.5v18h18v-18H54.5z M216.5,234.5h18v-18h-18V234.5z"
          />
        </g>
      </g>
      <g>
        <g>
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FF0000"
            points="234.5,108.5 234.5,72.5 216.5,72.5 216.5,54.5
      180.5,54.5 180.5,36.5 126.5,36.5 126.5,54.5 144.5,54.5 144.5,90.5 126.5,90.5 126.5,108.5 90.5,108.5 90.5,90.5 72.5,90.5
      72.5,72.5 54.5,72.5 54.5,108.5 36.5,108.5 36.5,144.5 54.5,144.5 54.5,162.5 72.5,162.5 72.5,180.5 108.5,180.5 108.5,162.5
      126.5,162.5 126.5,144.5 162.5,144.5 162.5,162.5 180.5,162.5 180.5,180.5 216.5,180.5 216.5,162.5 234.5,162.5 234.5,144.5
      252.5,144.5 252.5,108.5     "
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#303030"
            d="M108.5,162.5v36h18v-36H108.5z M162.5,162.5v36h18v-36H162.5z
       M162.5,144.5h-36v18h36V144.5z M126.5,216.5h36v-18h-36V216.5z"
          />
        </g>
      </g>
      <g>
        <g>
          <rect
            x="126.5"
            y="162.5"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            width="36"
            height="36"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#D1CFD8"
            d="M36.5,162.5v18h18v-18H36.5z M234.5,162.5v18h18v-18H234.5z
       M216.5,198.5h-54v18h-36v-18h-54v-18h-18v36h18v18h36v18h72v-18h36v-18h18v-36h-18V198.5z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#514F60"
            d="M72.5,198.5h36v-18h-36V198.5z M180.5,198.5h36v-18h-36V198.5z
       M36.5,144.5v18h18v-18H36.5z M54.5,162.5v18h18v-18H54.5z M234.5,144.5v18h18v-18H234.5z M216.5,180.5h18v-18h-18V180.5z"
          />
        </g>
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FF7D81"
        d="M90.5,54.5h36v54h-36V54.5z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FF7D81"
        d="M108.5,36.5h18v72h-18V36.5z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FF7D81"
        d="M72.5,54.5h72v36h-72V54.5z"
      />
    </svg>
  );
};
