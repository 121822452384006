import React, { ChangeEvent } from 'react';
import '../styles.css';
import { NamedAPIResource } from 'pokenode-ts';
import { toTitleCase } from '../utils/helpers';
import { PokeballIcon } from './PokeballIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

export function PokedexList({
  searchValue,
  handleSearch,
  handleSelect,
  results
}: {
  searchValue: string;
  handleSearch: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  handleSelect: (name: string) => void;
  results: NamedAPIResource[];
}) {
  const searchHistory = useSelector((state: RootState) => state.searchHistory);

  return (
    <div className="pokedexList">
      <div className="searchUnit">
        <span className="header" data-testid="header">
          National Pokedex
        </span>
        <textarea
          value={searchValue}
          onChange={handleSearch}
          placeholder="Search for a Pokemon..."
          className="searchInput"
          data-testid="searchInput"
        />
        <span className="recentlyViewed">Recently viewed:</span>
        <ul className="searchHistory">
          {searchHistory.map((s, i) => (
            <li key={i} className="historyItem" onClick={() => handleSelect(s)}>
              {toTitleCase(s)}
            </li>
          ))}
        </ul>
      </div>
      <div className="searchResults">
        {results.map((s, i) => (
          <div
            key={i}
            className="entry"
            onClick={() => {
              handleSelect(s.name);
            }}
            data-testid="result"
          >
            <span className="pokeballIcon">
              <PokeballIcon />
            </span>
            <span>{toTitleCase(s.name)}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
