import React from 'react';
import '../styles.css';
import { PokemonWithEvolutions } from '../types';
import { toTitleCase } from '../utils/helpers';

export function PokedexEntry({
  data,
  handleSelect,
  loading
}: {
  data: PokemonWithEvolutions | null;
  handleSelect: (name: string) => void;
  loading: boolean;
}) {
  return (
    <div className="pokedexEntry">
      {loading ? (
        <div style={{ margin: '20px' }}>Loading...</div>
      ) : (
        <>
          {data ? (
            <div className="entryDataContainer">
              <img
                src={
                  data.sprites.front_default ? data.sprites.front_default : ''
                }
                alt="Sprite"
                className="sprite"
              />
              <span data-testid="pokemonName">
                Name: {toTitleCase(data.name)}
              </span>
              <span data-testid="pokemonHeight">
                Height: {data.height * 10} cm
              </span>
              <span data-testid="pokemonWeight">
                Weight: {data.weight / 10} kg
              </span>
              <span>
                Types:{' '}
                {data.types.map((t) => toTitleCase(t.type.name)).join(', ')}
              </span>
              <span>Species: {toTitleCase(data.species.name)}</span>
              <span>
                Abilities:{' '}
                {data.abilities
                  .map((a) => toTitleCase(a.ability.name))
                  .join(', ')}
              </span>
              <span>
                Evolutions:{' '}
                {data.evolutionChain.map((e, i) => (
                  <>
                    <span className="evolution" onClick={() => handleSelect(e)}>
                      {toTitleCase(e)}
                    </span>
                    {i < data.evolutionChain.length - 1 && <span> &gt; </span>}
                  </>
                ))}
              </span>
              <span>Moves List:</span>
              <ul className="movesList">
                {data.moves.map((m, i) => (
                  <li key={i}>{toTitleCase(m.move.name)}</li>
                ))}
              </ul>
            </div>
          ) : (
            <div style={{ margin: '20px' }} data-testid="defaultMessage">
              Select a Pokemon to view its data!
            </div>
          )}
        </>
      )}
    </div>
  );
}
