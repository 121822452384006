import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { NamedAPIResource } from 'pokenode-ts';

const initialState: NamedAPIResource[] = [];

export const allPokemonSlice = createSlice({
  name: 'allPokemon',
  initialState,
  reducers: {
    populate: (state, action: PayloadAction<NamedAPIResource[]>) => {
      state = action.payload;
      return state;
    }
  }
});

export const { populate } = allPokemonSlice.actions;

export default allPokemonSlice.reducer;
