import React, {
  ChangeEvent,
  useEffect,
  useState,
  useCallback,
  useMemo
} from 'react';
import '../styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { NamedAPIResource, PokemonClient, EvolutionClient } from 'pokenode-ts';
import { populate } from '../redux/allPokemonSlice';
import { push } from '../redux/searchHistorySlice';
import { RootState } from '../redux/store';
import { buildEvolutions, debounce } from '../utils/helpers';
import { PokedexEntry } from './PokedexEntry';
import { PokedexList } from './PokedexList';
import { PokemonWithEvolutions } from '../types';

export function PokedexHome() {
  const dispatch = useDispatch();
  const allPokemon = useSelector((state: RootState) => state.allPokemon);
  const [selectedPokemon, setSelectedPokemon] =
    useState<PokemonWithEvolutions | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState<NamedAPIResource[]>([]);
  const [loading, setLoading] = useState(false);

  const pokemonClient = useMemo(() => new PokemonClient(), []);
  const evolutionClient = useMemo(() => new EvolutionClient(), []);

  const handleSearch = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setSearchValue(event.target.value.toString());
  };

  const handleSelectPokemon = async (name: string) => {
    setLoading(true);
    const pokemonData = await pokemonClient.getPokemonByName(name);
    const evolutionChain = await getEvolutions(pokemonData.species.name);
    setSelectedPokemon({ ...pokemonData, evolutionChain });
    dispatch(push(name));
    setLoading(false);
  };

  const getEvolutions = async (speciesName: string) => {
    const speciesData =
      await pokemonClient.getPokemonSpeciesByName(speciesName);
    const chainUrlParts = speciesData.evolution_chain.url.split('/');
    const chainId = parseInt(chainUrlParts[chainUrlParts.length - 2]);
    const evolutionChain = await evolutionClient.getEvolutionChainById(chainId);
    return buildEvolutions(evolutionChain);
  };

  // The deps for this callback are intentionally not exhaustive
  // eslint-disable-next-line
  const searchPokemon = useCallback(
    debounce((search: string) => {
      const normalizedSearchValue = search.toString().trim().toLowerCase();
      const results = allPokemon.filter((p) =>
        p.name.includes(normalizedSearchValue)
      );
      setSearchResults(results);
    }, 500),
    [allPokemon]
  );

  useEffect(() => {
    if (!allPokemon.length) {
      const fetchAllPokemon = async () => {
        const allPokemon = await pokemonClient.listPokemons(0, 1292);
        dispatch(populate(allPokemon.results));
      };
      fetchAllPokemon();
    }
    if (searchValue) {
      searchPokemon(searchValue);
    } else {
      setSearchResults([]);
    }
  }, [searchValue, searchPokemon, allPokemon, dispatch, pokemonClient]);

  return (
    <div className="main">
      <PokedexList
        searchValue={searchValue}
        handleSearch={handleSearch}
        handleSelect={handleSelectPokemon}
        results={searchValue ? searchResults : allPokemon}
      />
      <PokedexEntry
        data={selectedPokemon}
        handleSelect={handleSelectPokemon}
        loading={loading}
      />
    </div>
  );
}
