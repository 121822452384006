import { EvolutionChain } from 'pokenode-ts';

export const debounce = (fn: any, delay: number) => {
  let timeout: NodeJS.Timeout;

  return (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn(args), delay);
  };
};

export const toTitleCase = (value: string) => {
  return value.replace(/\w\S*/g, function (val) {
    return val
      .toLowerCase()
      .replace(/(?<!\S)\S/g, (initial) => initial.toUpperCase());
  });
};

export const buildEvolutions = (chain: EvolutionChain) => {
  const orderedSpecies = [chain.chain.species.name];
  let currentChainEntry = chain.chain;
  while (currentChainEntry.evolves_to.length) {
    orderedSpecies.push(currentChainEntry.evolves_to[0].species.name);
    currentChainEntry = currentChainEntry.evolves_to[0];
  }
  return orderedSpecies;
};
